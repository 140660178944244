<template>
  <div>
    <div class="hero is-primary">
      <div class="hero-body">
        <div class="container">
          <h1 class="title is-size-1">{{ title }}</h1>
        </div>
      </div>
    </div>
    <div class="container">
      <v-card :loading="loading" class="mb-4 pa-6">
        <v-row>
          <v-col cols="4">
            <company-select
              @chosen="setCompany"
              v-model="selectedCompany"
            ></company-select
          ></v-col>
          <v-col cols="3">
            <v-text-field
                outlined
                dense
                type="date"
                label="charge date"
                v-model="form.charge_date"
              ></v-text-field>
          </v-col>
          <v-col cols="3">
            <v-text-field
              label="charge type"
              v-model="form.charge_type"
              outlined
              dense
            ></v-text-field>
          </v-col>
          <v-col cols="3">
            <v-text-field
              label="reference"
              v-model="form.reference"
              outlined
              dense
            ></v-text-field>
          </v-col>
          <v-col cols="3">
            <v-text-field
              label="client reference"
              v-model="form.client_reference"
              outlined
              dense
            ></v-text-field>
          </v-col>
          <v-col cols="3">
            <v-text-field
              label="UOM"
              v-model="form.UOM"
              outlined
              dense
            ></v-text-field>
          </v-col>
          <v-col cols="3">
            <v-text-field
              label="billable units"
              v-model="form.billable_units"
                type="number"
              outlined
              dense
            ></v-text-field>
          </v-col>
          <v-col cols="3">
            <v-text-field
              label="tariff"
              v-model="form.tariff"
                type="number"
              outlined
              dense
            ></v-text-field>
          </v-col>
          <v-col cols="3">
            <v-text-field
              label="total charge"
              v-model="form.total_charge"
                type="number"
              outlined
              dense
            ></v-text-field>
          </v-col>
          <v-col cols="3">
            <v-text-field
              label="charge code"
              v-model="form.charge_code"
              outlined
              dense
            ></v-text-field>
          </v-col>
          <v-col cols="3">
            <v-text-field
              label="invoice number"
              v-model="form.invoice_number"
              outlined
              dense
            ></v-text-field>
          </v-col>
          <v-col cols="3">
            <v-select
            v-model="form.Credit_Type"
            :items="creditTypeList"
            dense
            outlined
            menu-props="auto"
            label="Credit Type"
          ></v-select>
          </v-col>
          <v-col cols="3">
            <v-select
            v-model="form.Credit_SubType"
            :items="creditSubTypeList"
            dense
            outlined
            menu-props="auto"
            label="Credit Type"
          ></v-select>
          </v-col>
          <v-col cols="3">
            <v-btn
          color="primary"
          outlined
          @click="insertCreditNotes()"
          block
          :loading="loading"
          >Save credit note</v-btn
        >
          </v-col>
     
        </v-row>
      </v-card>
      <v-card class="pa-4 mt-4">
        <v-row>
          <v-col cols="12">
            <h1>Bulk Upload</h1>
          </v-col>
          <v-col cols="3">
            <v-btn color="green" outlined @click="downloadTemplate" block
              >Download Template</v-btn
            >
          </v-col>
          <v-col cols="3">
            <v-file-input
              show-size
              counter
              multiple
              dense
              outlined
              prepend-icon=""
              prepend-inner-icon="attach_file"
              label="File input"
              @change="handleFileUpload"
            ></v-file-input>
          </v-col>
          <v-col cols="12">
            <v-card class="pa-4" v-if="data.length" outlined>
        
        <v-card-title>File Preview</v-card-title>
        <ExportButtons
          :headers="headers"
          :data="data"
          :title="'formatted_credit_notes'"
        />
        <v-data-table
          :headers="headers"
          :items="data"
          :items-per-page="5"
        ></v-data-table>
        <v-btn
          color="primary"
          outlined
          @click="insertCreditNotes(true)"
          block
          :loading="loading"
          >Save {{ data.length }} credit notes</v-btn
        >
      </v-card>
      <v-card class="pa-4" v-else outlined>
        <v-card-title>input a file to bulk upload</v-card-title>
      </v-card>
          </v-col>
        </v-row>
      </v-card>
      <v-card class="pa-4 mt-4">
        <v-card-title>View uploaded credit notes</v-card-title>
        <v-row>
            <v-col cols="6">
                <ExportButtons
          :headers="creditNotesHeaders"
          :data="creditNotes"
          :title="'credit_notes'"
        />
            </v-col>
            <v-col cols="6">
                <v-text-field
                    v-model="search"
                    append-icon="search"
                    label="Search"
                    single-line
                    hide-details
                    outlined
                    dense
                  ></v-text-field>
            </v-col>
            <v-col cols="12">
                <v-data-table
              :headers="creditNotesHeaders"
              :items="creditNotes"
              :items-per-page="5"
              :search="search"
            >
        
              <template v-slot:item.actions="{ item }">
                <v-btn
                  class="mx-2"
                  fab
                  dark
                  small
                  color="#B22222"
                  :disabled="loading"
                  @click="deleteEntry(item)"
                >
                  <v-icon dark>mdi-delete</v-icon>
                </v-btn>
              </template>
            </v-data-table>
            </v-col>
        </v-row>
        </v-card>


    
    </div>
    <v-snackbar v-model="snackbar" :timeout="3000">
      {{ text }}
      <v-btn color="blue" text @click="snackbar = false"></v-btn>
    </v-snackbar>
  </div>
</template>
  
  <script>
import ExcelMixin from "@/mixins/Excel";
import CommonFunctionsMixin from "@/mixins/BillingCommonFunctions";
import ExportButtons from "@/components/ExportButtons";
import BillingSnap from "@/services/BillingSnap.js";
import CompanySelect from "@/components/CompanySelectUpdated";
export default {
  components: { ExportButtons, CompanySelect },
  mixins: [ExcelMixin, CommonFunctionsMixin],
  data() {
    return {
      validForm: true,
      // Title
      title: "Credit Notes",
      // Snackbar
      snackbar: false,
      text: "",
      loading: false,

      file: null,
      data: [],
      headers: [],
      selectedCompany: null,
      creditTypeList: ['Billing Error', 'Warehouse', 'Carrier', 'GoodWill'],
      creditSubTypeList: ['Manual Error','Config Error','Coding Error'
      ,'Miss Pick','Short Pick','Over Pick'
      ,'Quality Issue','Carrier Non Receipt','Non Delivery','Damage',
      'Late Delivery','Carrier Billing Error','Carrier Non Receipt',
      'Consequential Loss','System Outage / Error', 'Stock Losses'],
      form: {
        company_code: "",
        prep_id: "",
        charge_date: "",
        charge_type: "",
        reference: "",
        client_reference: "",
        UOM: "",
        billable_units: "",
        tariff: "",
        total_charge: "",
        charge_code: "",
        invoice_number: "",
        committed: 1,
        Credit_Type: "",
        Credit_SubType: "",
      },
      creditNotes: [],
      creditNotesHeaders: [],
      search: "",
    };
  },

  created() {
    this.getCreditNotes()
  },
  methods: {
    async getCreditNotes() {
      this.loading = true;
      BillingSnap.getCreditNotes()
        .then(
          ((data) => {
            this.creditNotes = data
            this.loading = false;
            this.snackbar = true;
            this.text = `Successfully Loaded Data`;
            this.creditNotesHeaders = this.getHeaders(data, [], true)
          })
        )
        .catch((error) => {
          this.snackbar = true;
          this.text = `${error.response.data.message}`;
          this.loading = false;
          return;
        });
    },
    deleteEntry(entry) {
      if (confirm("Confirm Deletion of Entry with id " + entry['# id'])) {
        BillingSnap.deleteCreditNote({ id: entry['# id'] })
          .then(() => {
            this.getCreditNotes();
            this.loading = false;
          })
          .catch((error) => {
            this.snackbar = true;
            this.text = `${error.response.data.message}`;
            this.loading = false;
            return;
          });
      }
    },
    resetForm(){
        this.form = {
        company_code: "",
        prep_id: "",
        charge_date: "",
        charge_type: "",
        reference: "",
        client_reference: "",
        UOM: "",
        billable_units: "",
        tariff: "",
        total_charge: "",
        charge_code: "",
        invoice_number: "",
        committed: 1,
        Credit_Type: "",
        Credit_SubType: "",
      }
      this.data = []
    }, 
    setCompany(company) {
      this.form.company_code = company.code;
    },
    insertCreditNotes(bulk = false) {
        let reqBody = {creditNotes: [this.form]}
      if(bulk){
        if (!this.data.length) return;
        reqBody.creditNotes = this.data
      }
   
      this.loading = true;

      BillingSnap.insertCreditNotes(reqBody)
        .then((response) => {
          this.loading = false;
          this.snackbar = true;
          this.getCreditNotes()
          this.text = `${response}`;
          this.resetForm()
        })
        .catch((error) => {
          this.snackbar = true;
          this.text = error.response
            ? error.response.data.message
            : `${error.message}`;
          this.loading = false;
        });
    },

    async handleFileUpload(ev) {
      const file = ev[0];
      this.file = file;

      if (file) {
        this.extractJson(file)
          .then((jsonSpreadsheet) => {
            this.data = this.parseDates(jsonSpreadsheet);
            this.headers = this.getHeaders(jsonSpreadsheet);
          })
          .catch((err) => {
            this.snackbar = true;
            this.text = err;
          });
      }
    },
    parseDates(data) {
      for (const row of data) {
        const dateStr =
          row.charge_date instanceof Date
            ? row.charge_date.toLocaleDateString()
            : row.charge_date;
        const dbDateStr = this.convertToDbDate(dateStr);
        row.charge_date = dbDateStr;
      }
      return data;
    },
    convertToDbDate(dateString) {
      // Split the date string into day, month, and year
      const [day, month, year] = dateString.split("/");

      // Rearrange to 'yyyy-mm-dd' and return the result
      return `${year}-${month}-${day}`;
    },

    downloadTemplate() {
      let template = {
        company_code: "FMW",
        prep_id: "",
        charge_date: "18/10/2024",
        charge_type: "Credit Note",
        reference: "Billing Error",
        client_reference: "Manual Error",
        UOM: "Per Credit",
        billable_units: "2",
        tariff: "11.11",
        total_charge: "22.22",
        charge_code: "4300",
        invoice_number: "CN-2132",
        Credit_Type: "BillingError",
        Credit_SubType: "Manual Error",
      };
      this.generateTemplate(template, "credit_notes");
    },
  },
};
</script>
  <style></style>
  